import { useState } from "react";
import "./PopupMedia.css";

export const PopupMedia = (props) => {
  const image = props.mediaRef;
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const openGallery = () => {
    setIsGalleryOpen(true);
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };

  const ErrorHandler = () => {
    setIsLoaded(true);
    setIsError(true);
  };

  return (
    <div className="message-wrapper">
      <div className="message-content">
        {!isLoaded && <div className="media-shimmer shine-effect"></div>}
        <div className="form-row">
          <div className="col">
            <div className="gallery-image">
              {isError && <span>Couldn't load media...</span>}
              <img
                className="img-fluid rounded"
                src={image}
                alt="Overlay"
                onClick={() => openGallery()}
                style={{ cursor: "pointer", maxHeight: "500px" }}
                onLoad={() => setIsLoaded(true)}
                onError={ErrorHandler}
              />
            </div>
          </div>
        </div>
      </div>
      {isGalleryOpen && (
        <div
          className="overlay-media"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closeGallery();
            }
          }}
        >
          <div className="overlay-content-media">
            <img className="overlay-image-media" src={image} alt="Overlay" />
            <button
              className="close-button-media"
              onClick={() => closeGallery()}
            >
              x
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
