export const RecordPlayer = (props) => {
  const audioMsg = props.audioMsg;

  if (!audioMsg) {
    return (
      <div className="message-wrapper">
        <div className="message-content">
          <span>
            <em>Empty audio...</em>
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <audio controls>
        <source src={audioMsg} type="audio/mp3" />
        <source src={audioMsg} type="audio/ogg" />
        <source src={audioMsg} type="audio/wav" />
        <source src={audioMsg} type="audio/mpeg" />
        <source src={audioMsg} type="audio/aac" />
        <source src={audioMsg} type="audio/aac; codecs=mp4a.40.2" />
        <source src={audioMsg} type="audio/aac; codecs=adts" />
        <source src={audioMsg} type="audio/webm" />
        <source src={audioMsg} type="audio/webm; codecs=opus" />
      </audio>
    );
  }
};
