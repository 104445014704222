import "./Modal.css";

export const Modal = (props) => {
  return (
    <div className="modal-popup">
      <div className="modal-popup__content">
        <h3>Are you sure you want to sign out?</h3>
        <hr />
        <div className="modal-popup__footer">
          <button className="btn btn-primary" onClick={props.handleSignOut}>
            Sign Out
          </button>
          <button className="btn btn-secondary" onClick={props.closeModal}>
            Cancel
          </button>
        </div>

        <a href="#" className="modal-popup__close" onClick={props.closeModal}>
          &times;
        </a>
      </div>
    </div>
  );
};
